exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-austria-2020-js": () => import("./../../../src/pages/austria-2020.js" /* webpackChunkName: "component---src-pages-austria-2020-js" */),
  "component---src-pages-christmas-trip-js": () => import("./../../../src/pages/christmas-trip.js" /* webpackChunkName: "component---src-pages-christmas-trip-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cruises-js": () => import("./../../../src/pages/cruises.js" /* webpackChunkName: "component---src-pages-cruises-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-private-trips-js": () => import("./../../../src/pages/private-trips.js" /* webpackChunkName: "component---src-pages-private-trips-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

